import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class LoadingService {
    private displayloading = new BehaviorSubject(false);

    showLoading(): void {
        this.displayloading.next(true);
    }

    hideLoading(): void {
        this.displayloading.next(false);
    }

    completedLoading(): void {
        this.displayloading.complete();
    }

    getLoading(): Observable<boolean> {
        return this.displayloading.asObservable();
    }
}
