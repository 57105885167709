import { NgxLoggerLevel } from "ngx-logger";
import { env_data } from "./env-data";

export const environment = {
  production: false,
  apiRootUrl: 'https://dfs.claimsvc.test.c1.statefarm/gateway',
  feConfig: {
    skipSaveHttp: false,
  },
  useConsoleLogger: false,
  logger: {
    serverLoggingUrl : "",
    level: NgxLoggerLevel.DEBUG,
    serverLogLevel: NgxLoggerLevel.ERROR,
    disableConsoleLogging: false,
    httpResponseType: "json",
    context: 'forms-client'
  }
};

export const url = {
  proofing: env_data.proofing,
  clifflogurl: 'https://ngw6464h68.execute-api.us-east-1.amazonaws.com/cliff/logger',
  clifflogapi: 'ee2AS1YCVY8okhCXKz1lI6qqJyaCIB2f932rtsjm',
  claimsHub: "https://hub.claims.test.statefarm.com/#/",
 
  authdetails: environment.apiRootUrl + '/authentication',
  participantsname: environment.apiRootUrl + '/participantName',
  participantDetails: environment.apiRootUrl + '/participantDetails',
  contactDetails: environment.apiRootUrl + '/contactDetails',
  dateofloss: environment.apiRootUrl + '/dateOfLoss',
  vehicleDetails: environment.apiRootUrl + '/vehicleDetails',
  standardization: environment.apiRootUrl + '/standardizationAddress',
  claimNumber: environment.apiRootUrl + '/claimNumber',
  clientId: environment.apiRootUrl + '/clientId',
  submit_url: environment.apiRootUrl + '/completeDQFC',
  interactions_url: environment.apiRootUrl + '/interactionDQFC',
  health: environment.apiRootUrl + '/health'
};
