import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { RouteParamsService } from './routing/service/route-params.service';
import { IdService } from './observablility/services/id.service';
import { LOGGER } from './observablility/logger/token';
import { HealthCheckService } from './shared/services/global/health-check.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    
  ]
})
export class AppComponent implements OnInit {

  router                           = inject(Router)
  ids                              = inject(IdService)
  logger                           = inject(LOGGER)
  routeParamsService               = inject(RouteParamsService)
  healthCheckService               = inject(HealthCheckService)

  ngOnInit(): void {
    this.healthCheckService.healthCheck();
    this.ids.newSession();
    this.logger.debug("New Session started with Id: ", this.ids.getSessionId())

    this.routeParamsService.setParameters()
    this.logger.debug("route Params Set")

    this.router.events.subscribe((evt) => {
      window.scrollTo(0, 0);
    });
  }
}
