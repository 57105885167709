import { Injectable, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CacheService } from "src/app/shared/services/helper/cache.service";
import { QueryParameters } from "../model/queryParameters.model";
import { ValidatorService } from "src/app/shared/services/helper/validator.service";

@Injectable({
    providedIn: 'root'
})
export class RouteParamsService {
    queryparameter: QueryParameters = new QueryParameters();

    constructor( ) { }

    route = inject(ActivatedRoute);
    router = inject(Router);
    cache = inject(CacheService);
    validate = inject(ValidatorService);

    navigate(navigate: string): void {
        this.router.navigate([navigate], { queryParamsHandling: "preserve" });
    }

    setParameters(): void {
        this.route.queryParams.subscribe((params: any) => {
            this.setClaimId(params['claimid']);
            this.setParticipantId(params['participantid']);
            this.setCMPId(params['cmpid']);
            this.setFormId(params['cmpid']);
            this.cache.setLocal("queryParams", this.queryparameter);
        });
    }

    getParameters(): QueryParameters {
        if (this.validate.checkEmpty(this.queryparameter.claimId) || this.validate.checkEmpty(this.queryparameter.participantId)) {
            this.queryparameter = this.cache.getLocal("queryParams");
        }
        return this.queryparameter;
    }

    setClaimId(value: string): void {
        if (this.validate.checkNotEmpty(value)) {
            this.queryparameter.claimId = value;
            this.cache.setLocal("claimId", value);
        }
    }

    getClaimId(): string {
        if (this.validate.checkEmpty(this.queryparameter.claimId)) {
            this.queryparameter.claimId = this.cache.getLocal("claimId");
        }
        return this.queryparameter.claimId;
    }

    setParticipantId(value: string): void {
        if (this.validate.checkNotEmpty(value)) {
            this.queryparameter.participantId = value;
            this.cache.setLocal("participantId", value);
        }
    }

    getParticipantId(): string {
        if (this.validate.checkEmpty(this.queryparameter.participantId)) {
            this.queryparameter.participantId = this.cache.getLocal("participantId")
        }
        return this.queryparameter.participantId;
    }

    setCMPId(value: string): void {
        if (this.validate.checkNotEmpty(value)) {
            this.queryparameter.cmpId = value;
            this.cache.setLocal("cmpId", value);
        }
    }

    getCMPId(): string {
        if (this.validate.checkEmpty(this.queryparameter.cmpId)) {
            this.queryparameter.cmpId = this.cache.getLocal("cmpId")
        }
        return this.queryparameter.cmpId;
    }

    setFormId(value: string): void {
        if (this.validate.checkNotEmpty(value)) {
            this.queryparameter.formId = value;
            this.cache.setLocal("formId", value);
        }
    }

    getFormId(): string {
        if (this.validate.checkEmpty(this.queryparameter.formId)) {
            this.queryparameter.formId = this.cache.getLocal("formId")
        }
        return this.queryparameter.formId;
    }

    removeParameters() {
        this.cache.removeLocal('claimId');
        this.cache.removeLocal('participantId');
        this.cache.removeLocal('cmpId');
        this.cache.removeLocal('formId');
        this.cache.removeLocal('queryParams');
    }
}