<div id="landingId">
    <div class="-oneX-container">
        <div class="-oneX-row">
            <div class="-oneX-offset-lg-2 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-col-md-10 -oneX-col-sm-12">
                <div id="landing-heading" class="-oneX-heading--h1 landing-header">
                    Hi, <span *ngIf="name$ | async as name">{{name.firstname + " " + name.lastname | titlecase}}</span>,
                </div>
                <p class="-oneX-body--intro-md landing-intro">
                    We were sorry to hear about your recent accident. The other person involved in the incident reported
                    that it happened on <span *ngIf="dol$ | async as dol">{{dol.dol | longDate}}</span> and has started
                    a claim.
                    <br /><br />We wanted to verify and gather some details to keep your claim moving forward.
                    <span class="-hr">&nbsp;</span>
                    <br />This should only take 5-10 minutes. Information we will gather includes:
                </p>
            </div>
            <div class="-oneX-offset-lg-2 -oneX-offset-md-1 -oneX-col-lg-8 -oneX-col-md-10 -oneX-col-sm-12">
                <ul class="landing-number">
                    <li>
                        <span class="-oneX-heading--h2 number">01</span>
                        <span class="-oneX-body--primary text">Incident details</span>
                    </li>
                    <li>
                        <span class="-oneX-heading--h2 number">02</span>
                        <span class="-oneX-body--primary text">Individuals involved and injuries</span>
                    </li>
                    <li>
                        <span class="-oneX-heading--h2 number">03</span>
                        <span class="-oneX-body--primary text">Vehicle damage</span>
                    </li>
                </ul>
            </div>
            <div class="-oneX-offset-lg-3 -oneX-offset-md-1 -oneX-col-lg-6 -oneX-col-md-10 -oneX-col-sm-12">
                <div class="landing-login-container">
                    <button class="-oneX-btn-primary" (click)="RedirectToformsPage()">Get Started</button>
                </div>
            </div>
            <!-- <div class="-oneX-offset-lg-3 -oneX-offset-md-1 -oneX-col-lg-6 -oneX-col-md-10 -oneX-col-sm-12">
                <div class="landing-login-container">
                    <div data-type="1x-inpage-login" data-mobile-only="true"
                        [attr.data-login-destination-url]="loginDestinationUrl"></div>
                </div>
            </div> -->
        </div>
    </div>
</div>