import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  cacheName: string = 'digitalQFC';

  constructor() { }

  updateCache(url: string, res: Response): void {
    let resClone = res.clone();
    caches.open(this.cacheName).then(cache => {
      cache.put(url, resClone);
    });
  }

  getCache(url: string): Promise<any> {
    return caches.open(this.cacheName).then(cache => {
      return cache.match(url).then(res => {
        return res?.json();
      });
    });
  }

  deleteCache(url: string): void {
    caches.open(this.cacheName).then(cache => {
      cache.delete(url);
    });
  }

  deleteAllCache(): void {
    caches.delete(this.cacheName).then(() => {
      console.log('Cache successfully deleted!');
    })
  }

  setLocal(property: string, value: any): void {
    let modifiedvalue = value;
    if (value !== null && typeof value === 'object') {
      modifiedvalue = JSON.stringify(value) + '$$object$$';
    }
    localStorage.setItem(property, modifiedvalue);
  }

  getLocal(property: string): any {
    let modifiedvalue = localStorage.getItem(property);
    if (modifiedvalue !== null && modifiedvalue.includes('$$object$$')) {
      modifiedvalue = modifiedvalue.replace('$$object$$', '');
      modifiedvalue = JSON.parse(modifiedvalue);
    }
    return modifiedvalue;
  }

  removeLocal(property: string): void {
    localStorage.removeItem(property);
  }

  removeAllLocal(): void {
    localStorage.clear();
  }

  setSession(property: string, value: any): void {
    let modifiedvalue = value;
    if (value !== null && typeof value === 'object') {
      modifiedvalue = JSON.stringify(value) + '$$object$$';
    }
    sessionStorage.setItem(property, modifiedvalue);
  }

  getSession(property: string): any {
    let modifiedvalue = sessionStorage.getItem(property);
    if (modifiedvalue !== null && modifiedvalue.includes('$$object$$')) {
      modifiedvalue = modifiedvalue.replace('$$object$$', '');
      modifiedvalue = JSON.parse(modifiedvalue);
    }
    return modifiedvalue;
  }

  removeSession(property: string): void {
    sessionStorage.removeItem(property);
  }

  removeAllSession(): void {
    sessionStorage.clear();
  }
}
