import { Inject, NgModule } from '@angular/core';
import { Router, RouterEvent, RouterModule } from '@angular/router';
import { routes } from './routes';
import { environment } from 'src/environments/environment';
import { LOGGER, Logger } from '../observablility/logger/token';



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private readonly router: Router,
    @Inject(LOGGER) private logger: Logger
  ) {
    this.router.events.subscribe((event) => {
    })
  }

}
