export class QueryParameters {
    claimId: string;
    participantId: string;
    cmpId: string;
    formId: string;

    constructor() {
        this.claimId = '';
        this.participantId = '';
        this.cmpId = '';
        this.formId = '';
    }
}
