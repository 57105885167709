import { Component, OnInit, inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/analytics/logsandtags/sf-analytics.service';

@Component({
  selector: 'app-technical-error',
  templateUrl: './technical-error.component.html',
  styleUrls: ['./technical-error.component.scss']
})
export class TechnicalErrorComponent implements OnInit {
  adobeScreenId = "technical-error"

  //* Services
  adobe = inject(SfAnalyticsService)

  ngOnInit() {
    this.adobe.sendData(this.adobeScreenId)
  }

}
