import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { AuthenticationService } from 'src/app/external/service/authentication.service';
import { Dol } from '../../models/dol.model';
import { CookieService } from '../helper/cookie.service';
import { CorrelationIdService } from '../helper/correlation-id.service';
import { UrlService } from '../helper/url.service';
import { ValidatorService } from '../helper/validator.service';

@Injectable({
  providedIn: 'root'
})
export class DolService {
  dol: Dol = new Dol();

  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private correlationIdService: CorrelationIdService,
    private validatorService: ValidatorService,
    private authenticationService: AuthenticationService,
    private cookieservice: CookieService
  ) { }

  DOL(external_claim_id: string, external_participant_id: string): Observable<Dol> {
    if (this.validatorService.checkEmpty(this.dol.dol)) {
      const correlation = this.correlationIdService.getCorrelationId();
      const ssotoken = this.cookieservice.getSSOToken();
      const auth = this.cookieservice.getOktaJWT();
      let dolurl = this.urlService.getServiceUrl('dateofloss');
      dolurl = dolurl + "?external_claim_id=" + external_claim_id + "&external_participant_id=" + external_participant_id + "";
      const headeroptions = {
        headers: new HttpHeaders({
          'correlation-id': correlation,
          'X_SF_SSO_TOKEN': ssotoken,
          'Authorization': auth
        })
      };
      return this.http.get(dolurl, headeroptions).pipe(
        switchMap((response: any) => {
          this.dol.dol = response.dol
          return of(this.dol)
        }),
        catchError((err: any) => {
          console.log(err)
          this.authenticationService.handleRoute(err);
          throw Error(`Error: ` + err)
        })
      )
    } else {
      return of(this.dol);
    }
  }

  getDOL() {
    return this.dol;
  }
}
