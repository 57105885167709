import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'longDate'
})
export class LongDatePipe implements PipeTransform {

  transform(value: string): string {
    const date = value.split('-');
    const year = date[0];
    const monthnum = Number(date[1]);
    const day = date[2];
    const month = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"][monthnum - 1];
    const val = month + " " + day + ", " + year;
    return val;
  }

}
