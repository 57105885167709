import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { catchError, Observable, of, switchMap } from 'rxjs'
import { CardInput } from '../../models/select-card.model'
import { VehicleDetails } from '../../models/app-models/vehicleDetails.model'
import { CookieService } from '../../../shared/services/helper/cookie.service'
import { CorrelationIdService } from '../../../shared/services/helper/correlation-id.service'
import { UrlService } from '../../../shared/services/helper/url.service'
import { CacheService } from 'src/app/shared/services/helper/cache.service'

@Injectable({
  providedIn: 'root'
})
export class VehicleDetailsService {
  constructor(
    private http: HttpClient,
    private urlService: UrlService,
    private correlationIdService: CorrelationIdService,
    private cookieservice: CookieService,
    private cache: CacheService) { }
  vehicles: CardInput[] = []

  getVehicleInformation(external_claim_id: string): Observable<CardInput[]> {
    const cachedVehicles = this.cache.getSession("vehicleDetails");
    if (this.vehicles.length > 0) {
      return of(this.vehicles);
    } else if (cachedVehicles) {
      this.vehicles = cachedVehicles;
      return of(cachedVehicles);
    } else {
      const correlation = this.correlationIdService.getCorrelationId();
      const ssotoken = this.cookieservice.getSSOToken();
      const auth = this.cookieservice.getOktaJWT();
      let vehicleDetailsUrl = this.urlService.getServiceUrl('vehicleDetails');
      vehicleDetailsUrl = vehicleDetailsUrl + "?external_claim_id=" + external_claim_id + "";
      const headeroptions = {
        headers: new HttpHeaders({
          'correlation-id': correlation,
          'X_SF_SSO_TOKEN': ssotoken,
          'Authorization': auth,
        })
      }
      return this.http.get(vehicleDetailsUrl, headeroptions).pipe(
        switchMap((response: any) => {
          this.vehicles = this.formatVehicleDetails(response);
          this.cache.setSession("vehicleDetails", this.vehicles);
          return of(this.vehicles);
        }),
        catchError((err) => {
          throw Error(err)
        })
      )
    }
  }

  formatVehicleDetails(res: Array<VehicleDetails>): CardInput[] {
    let tempCardInfo: CardInput = {
      id: '',
      body: [],
      isIcon: false,
      title: '',
      icon: ''
    };
    res.forEach((eachCard: any) => {
      tempCardInfo.title = eachCard.year + " " + eachCard.make + " " + eachCard.model;
      tempCardInfo.id = "Id_" + eachCard.id;
      tempCardInfo.isIcon = true;
      tempCardInfo.icon = 'auto_insurance_24';
      tempCardInfo.body.push({
        "line": "VIN :" + eachCard.vin
      });
    });
    return [tempCardInfo];
  }

  removeVehicleDetails(): void {
    this.vehicles = [];
    this.cache.removeSession("vehicleDetails");
  }
}
