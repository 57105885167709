<div class="-oneX-container" id="confirmation">
    <div class="-oneX-row">
        <div class="-oneX-col-12">
            <div class="information">
                <div class="success-icon"><img src="../../../assets/image/confirmed.svg" /></div>
                <h3 class="-oneX-heading--h3 header">We've collected all the information needed at this time</h3>
                <div class="-oneX-body--primary paragraph" *ngIf="claimNumber$ | async as refnum">
                    For reference, your claim number is<br />
                    <span class="capitalize">{{refnum.claimNumber | claimNumber}}</span>.
                </div>
                <div class="-oneX-body--primary paragraph">
                    <b>Let's keep your claim moving forward</b><br />
                    Continue to Claim Overview for your next steps, view the status of your claim, repair options,
                    rental information, update your payment & communication preferences and more.
                </div>
                <div class="-oneX-d-flex -oneX-justify-content-center continue" *ngIf="claimsHubURL$ | async as url">
                    <a [href]="url" class="-oneX-btn-primary__anchor">Continue to Claim Overview</a>
                </div>
            </div>
        </div>
    </div>
</div>
