import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../services/loading.service';


@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  subscriptionloading: Subscription = new Subscription();
  displayloading: boolean;

  constructor(private loadingservice: LoadingService) {
    this.displayloading = false;
  }

  ngOnInit(): void {
    this.subscriptionloading = this.loadingservice.getLoading().subscribe((data: boolean) => {
      this.displayloading = data;
    });
  }

  ngOnDestroy(): void {
    this.subscriptionloading.unsubscribe();
  }
}
