import { Injectable } from '@angular/core';
import { v1 as uuidv4 } from 'uuid';
import { CacheService } from './cache.service';
import { ValidatorService } from './validator.service';

@Injectable({
  providedIn: 'root'
})
export class CorrelationIdService {
  private correlationId: string;

  constructor(
    private cacheService: CacheService,
    private validatorService: ValidatorService
  ) {
    this.correlationId = '';
  }

  public setCorrelationId(): void {
    const cachedCorrId = this.cacheService.getLocal('correlationId');
    if (this.validatorService.checkNotEmpty(cachedCorrId)) {
      this.correlationId = cachedCorrId;
    } else {
      const result = uuidv4();
      this.correlationId = result;
      this.cacheService.setLocal('correlationId', result);
    }
  }

  public getCorrelationId(): string {
    if (this.correlationId === '') {
      this.setCorrelationId();
    }
    return this.correlationId;
  }
}
