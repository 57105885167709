import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'claimNumber'
})
export class ClaimnumberPipe implements PipeTransform {

  transform(value: string): string {
    const convertedVal = value.toString();
    const reg = /([A-Z0-9]{2})([A-Z0-9]{4})([A-Z0-9]{2,5})/;
    const pattern = '$1-$2-$3';
    let val = convertedVal.replace(/[\$()-\s]/g, '');
    val = val.replace(reg, pattern);
    return val;
  }

}
