<div class="loading" [hidden]="!displayloading">
    <div class="-oneX--scrim__modal -oneX--scrim__on"></div>
    <div class="-oneX-page-loader page-loader" tabindex="-1">
        <h2 class="mt-0">Loading...</h2>
        <div class="-oneX-loader-icon" aria-hidden="true"></div>
        <div class="-oneX-loaderdiv" aria-hidden="true">
            <svg class="-oneX-small">
                <circle cx="32" cy="32" r="28.8" viewBox="0 0 64 64"></circle>
            </svg>
            <svg class="-oneX-medium">
                <circle cx="40" cy="40" r="36" viewBox="0 0 80 80"></circle>
            </svg>
            <svg class="-oneX-large">
                <circle cx="50" cy="50" r="45" viewBox="0 0 100 100"></circle>
            </svg>
        </div>
    </div>
</div>