<div id="technicalError">
    <div class="-oneX-container -oneX-d-flex -oneX-align-items-center -oneX-justify-content-center">
        <div class="-oneX-row">
            <div class="technical-error -oneX-col-12 text-center">
                <h2 class="-oneX-heading--h2">We're having a problem</h2>
                <p class="-oneX-body--primary">We have your responses saved, please retry after sometime. Or</p>
                <p class="-oneX-body--primary">We can help you by phone 24/7 at 800-SF-CLAIM (800-732-5246).</p>
                <a id="okbtn" role="button" href="https://www.statefarm.com" class="-oneX-btn-primary__anchor redirect">Ok</a>
            </div>
        </div>
    </div>
</div>