import { Component, OnInit, inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/analytics/logsandtags/sf-analytics.service';

@Component({
  selector: 'app-submitted',
  templateUrl: './submitted.component.html',
  styleUrls: ['./submitted.component.scss']
})
export class SubmittedComponent implements OnInit {
  adobeScreenId = "submitted"

  //* Services
  adobe = inject(SfAnalyticsService)

  ngOnInit() {
    this.adobe.sendData(this.adobeScreenId)
  }

}
