import { Routes } from "@angular/router";
import { MaintanenceComponent } from "../shared/pages/maintanence/maintanence.component";
import { TechnicalErrorComponent } from "../shared/pages/technical-error/technical-error.component";
import { SubmittedComponent } from "../shared/pages/submitted/submitted.component";

import { LandingComponent } from "../external/component/landing/landing.component";
import { ConfirmationComponent } from "../external/component/confirmation/confirmation.component";

import { AuthGuard } from "../external/guard/auth.guard";
import { MaintenanceGuard } from "../shared/services/guards/maintenance.guard";
import { QueryparamsGuard } from "./guard/queryparams.guard";

export const routes: Routes = [
  { path: '', redirectTo: 'landing', pathMatch: 'full' },
  { path: 'landing', component: LandingComponent, canActivate: [MaintenanceGuard, AuthGuard, QueryparamsGuard] },
  {
    path: 'forms',
    loadChildren: () =>
      import('../forms-engine/forms-engine.module').then(m => m.FormsEngineModule),

    //? leave this commented out till we go to prod or unless youre testing guards
    //? its annoying to have to change base url and login when you working locally on FE
    canLoad: [MaintenanceGuard, AuthGuard]
  },
  { path: 'confirmation', component: ConfirmationComponent, canActivate: [MaintenanceGuard, AuthGuard] },
  { path: 'technical-error', component: TechnicalErrorComponent },
  { path: 'submitted', component: SubmittedComponent },
  { path: 'maintenance', component: MaintanenceComponent },
  { path: '**', redirectTo: 'technical-error' }
];
