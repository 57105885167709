import { Injectable } from '@angular/core';
import { url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor() { }

  public getServiceUrl(urlkeyword: string): string {
    return url[urlkeyword];
  }

  public getFormatedClaimshubURL(claimId: string, clientId: string): string {
    const url = this.getServiceUrl('claimsHub');
    return url + 'claims/' + claimId + '/client/' + clientId;
  }
}
