import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { IdService } from 'src/app/observablility/services/id.service'
import { LOGGER } from '../logger/token'

import { context } from '@opentelemetry/api'
import { tracer } from 'src/otel'


@Injectable()
export class IdInterceptor implements HttpInterceptor {
    logger = inject(LOGGER)
    ids = inject(IdService)
    
    span = tracer.startSpan('client-request', { root: true }, context.active())
    traceId = this.span.spanContext().traceId 
        ? this.span.spanContext().traceId : 'error'
    
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {        
        const sessionId = this.ids.getSessionId(); //? Should auto generate a new session ID if it doesnt exist
        // const newRequestId = this.ids.generateID();
        
        this.logger.debug("interceptor: Id-Interceptor, trace-id: "+ this.traceId, ", session-id: ", sessionId);
        this.span.setAttribute('sessionId', sessionId);
        const modifiedReq = request.clone({
            headers: request.headers
            .set(
                'session-id',
                sessionId
            )
            .set(
                "trace-id",
                this.traceId
            )
        })
        
        //? To debug headers
        this.logger.debug("Id Headers ", modifiedReq.headers)
        return next.handle(modifiedReq)
    }
}
