import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  constructor() { }

  checkEmpty(val: string): boolean {
    return val === undefined || val === null || val.trim() === '';
  }

  checkNotEmpty(val: string): boolean {
    return val !== undefined && val !== null && val.trim() !== '';
  }

  checkPattern(val: string, pattern: string): boolean {
    const patternFormat = new RegExp(pattern);
    return patternFormat.test(val);
  }
}
