import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { 
    WebTracerProvider,
    ConsoleSpanExporter,
    BatchSpanProcessor,
    SimpleSpanProcessor,
} from '@opentelemetry/sdk-trace-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { IResource, Resource } from '@opentelemetry/resources';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { B3Propagator } from '@opentelemetry/propagator-b3';

import { AsyncHooksContextManager } from '@opentelemetry/context-async-hooks';


// https://github.com/open-telemetry/opentelemetry-js-contrib/tree/main/metapackages/auto-instrumentations-web
const resource: IResource = Resource.default().merge(
    new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'dfs.svc.client',
      [SemanticResourceAttributes.SERVICE_NAMESPACE]: 'public',
      [SemanticResourceAttributes.WEBENGINE_NAME]: 'Angular',
    })
)

const provider = new WebTracerProvider({
    resource: resource
});



// For demo purposes only, immediately log traces to the console
// provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
// const useOtlp = false
// if (useOtlp) {

//     // // Batch traces before sending them to Jaeger
//     provider.addSpanProcessor(
//         new BatchSpanProcessor(
//             new OTLPTraceExporter({
//                 url: 'https://dfs.claimsvc.clmsbx.test.c1.statefarm/otel',
//                 // headers: {
//                     //     'x-honeycomb-team': 'YOUR_KEY_HERE',
//                     // },
//                     hostname: "client",
                    
//                 }), 
//                 {
//                     maxExportBatchSize: 512
//                 }
//                 ),
//                 );
// } else {
//         // // Batch traces before sending them to Jaeger
//         provider.addSpanProcessor(
//             new BatchSpanProcessor(
//                 new OTLPTraceExporter({
//                     url: 'http://localhost:4318',
//                     // headers: {
//                         //     'x-honeycomb-team': 'YOUR_KEY_HERE',
//                         // },
//                         hostname: "client",
                        
//                     }), 
//                     {
//                         maxExportBatchSize: 512
//                     }
//                     ),
//                     );
// }


export const tracer = provider.getTracer('dfs.svc.client');

provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new B3Propagator()
});


 
registerInstrumentations({
    instrumentations: [
        getWebAutoInstrumentations({
            // not needed to add the following, but it better shows the intention
            '@opentelemetry/instrumentation-document-load': {
                enabled: true,
            },
            // '@opentelemetry/instrumentation-user-interaction': {
            //     enabled: true,
            // },
            '@opentelemetry/instrumentation-fetch': {
                enabled: true,
            },
            '@opentelemetry/instrumentation-xml-http-request': {
                enabled: true,
            },
        }),
    ],
});