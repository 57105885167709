import { Injectable, Optional, inject } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

// https://www.npmjs.com/package/uuid
@Injectable({
  providedIn: 'root'
})
export class IdService {
  sessionId = ""

  generateID(): string{
    return uuidv4();
  }

  newSession(){
    this.sessionId = this.generateID()
  }

  getSessionId(): string{
    if (this.sessionId ){
      return this.sessionId
    } else {
      this.newSession()
      return this.sessionId
    }
  }
}
