import { Component, OnInit, inject } from '@angular/core';
import { SfAnalyticsService } from '../../services/analytics/logsandtags/sf-analytics.service';

@Component({
  selector: 'app-maintanence',
  templateUrl: './maintanence.component.html',
  styleUrls: ['./maintanence.component.scss']
})
export class MaintanenceComponent implements OnInit {
  adobeScreenId = "maintanence"

  //* Services
  adobe = inject(SfAnalyticsService)

  ngOnInit() {
    this.adobe.sendData(this.adobeScreenId)
  }
}
