import { Injectable, inject } from '@angular/core';
import { CacheService } from '../../helper/cache.service';

@Injectable({
  providedIn: 'root'
})
export class SfAnalyticsService {
  readonly _channel = "claims-digitalqfc"; //Modify this accordingly. This is the only line that you need to modify !
  readonly _eventName = "screenChange"; //Do NOT modify this value!

  cache = inject(CacheService)

  constructor() {
    this.setInitialState()
  }

  sendData(id: any): void {
    var _w = this.getWindow();
    if (typeof _w.dataLayer === "undefined") {
      _w.dataLayer = { title: "", pageName: "", pageChannel: "", screenId: "", screenChannel: "", product: "", visitor: "", language: "", state: "", clientID: "", urlPath: {}, queryParams: {}, deploymentIds: [], policeReport: "", liabilityDetermined: "" };
    }
    _w.dataLayer.screenChannel = this._channel;
    _w.dataLayer.screenId = id.replace(/\//gi, "");
    this.createEvent(_w);
  }

  setValues(_obj: any): void {
    var _w = this.getWindow();
    if (typeof _w.dataLayer === "undefined") {
      _w.dataLayer = { title: "", pageName: "", pageChannel: "", screenId: "", screenChannel: "", product: "", visitor: "", language: "", state: "", clientID: "", urlPath: {}, queryParams: {}, deploymentIds: [], policeReport: "", liabilityDetermined: "" };
    }
    for (var _name in _obj) {
      _w.dataLayer[_name] = _obj[_name];
    }
  }

  setInitialState(): void {
    var _w = this.getWindow();
    _w.screenChange = true;
  }

  getWindow(): any {
    return window;
  }

  private createEvent(_win: any) {
    let _ev: any;
    let _w = _win;
    /* istanbul ignore else */
    if (typeof Event === 'function') {
      _ev = new Event(this._eventName, { 'bubbles': true, 'cancelable': false });
    } else {
      _ev = document.createEvent('Event');
      _ev.initEvent(this._eventName, true, false);
    }
    _win.setTimeout(function () { _w.dispatchEvent(_ev); }, 500);
  }



}
