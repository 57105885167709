<ng-container *ngIf="manualtoggle">
  <div class="-oneX--scrim__modal -oneX--scrim__on"></div>
  <div id="modal-session-timeout" class="-oneX-modal__container">
    <div class="-oneX-modal-content" aria-labelledby="modal-session-timeout-heading" role="dialog">
      <div id="modal-session-timeout-heading" class="-oneX-modal-heading centered" role="heading" aria-level="2"
        tabindex="-1">
        Session about to expire
      </div>
      <div class="-oneX-modal-body centered">
        Your session will expire in
        <span id="modal_timer" role="timer" aria-atomic="true" aria-live=" " class="-oneX-font--bold">{{ getMinutes()
          }}</span>:<span class="-oneX-font--bold">{{ getSeconds() }}</span>.<br />
        Any information you've entered on the current screen will not be saved.
        <h5 class="modal-title">
          Would you like to stay logged in?
        </h5>
      </div>
      <div class="-oneX-modal-footer">
        <div class="-oneX-row">
          <div class="-oneX-modal-primary__div -oneX-col">
            <button (click)="extendSession()" id="modal-session-extend" class="-oneX-btn-primary">
              Stay logged in
            </button>
          </div>
          <div class="-oneX-modal-secondary__div -oneX-col">
            <button (click)="logout()" id="modal-session-end" class="-oneX-btn-secondary" href="#">
              Log out
            </button>
          </div>
        </div>
      </div>
      <button id="modal-timeout-close" type="button" class="-oneX-close-X-button" aria-label="Close Modal"
        (click)="hideTimeoutModal()">
        <span class="-oneX-icon--close"></span>
      </button>
    </div>
  </div>
</ng-container>