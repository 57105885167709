import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UrlService } from '../helper/url.service';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  constructor(
    private http: HttpClient,
    private urlService: UrlService
  ) { }

  healthCheck(): Promise<any> {
    let healthurl = this.urlService.getServiceUrl('health');

    const response = lastValueFrom(this.http.get(healthurl))
      .then((res: any): Promise<any> => {
        console.log(res)
        return Promise.resolve(res)
      })
      .catch((err: any): Promise<any> => {
        console.log(err)
        return Promise.reject(err)
      })
    return response
  }
}
