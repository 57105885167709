import { InjectionToken } from "@angular/core";

export interface Logger {
    info(...msg: any): void
    warn(...msg: any): void
    error(...msg: any): void
    debug(...msg: any): void
    flushServerQueue(): void
}

export const LOGGER = new InjectionToken<Logger>('LOGGER')


export const consoleLogger: Logger = {
    info(...msg: any){
        console.info(...msg)
    },
    warn(...msg: any){
        console.warn(...msg)
    },
    error(...msg: any){
        console.error(...msg)
    },
    debug(...msg: any){
        console.debug(...msg)
    },
    flushServerQueue(){
        console.debug("flushServerQueue")
    }
}

